import React, { useEffect, useState } from 'react';
import { EnumCategory, EnumEvent } from '../../../../google-analytics-track-list';
import { ReportCustomEvent } from '../../../../GoogleAnalyticsConfig';
import { Trans } from '@lingui/react';
import { defineMessage } from '@lingui/macro';
import { linguiTranslateToString } from '../../../utils/lingui-utils/utils-lingui';
import CustomSelect from '../../../utils/custom-select/custom-select';
import { updateTourLeadsConfig } from '../../../../api-helper/api-tours';
import { ILeadConfig } from '../../../main/settings/settings';
import { EnumNotificationType } from '../../../utils/notifications/notification';
import { handleError } from '../../../../api-helper/api-error-handling';
import { useAuth } from '../../../../auth-routes/auth-context';
import { ISidebar } from '../../viewer-canvas';
import { useConfig } from '../../../utils/user-config-context/user-config-context';
import { createSubscriptionCheckoutSession } from '../../../../api-helper/api-payment';

export enum EnumLeadsInformationToCapture {
  //Name, Email address, Phone Number, Message
  All = '1',
  //Name, Email address, Message
  NoPhone = '2',
  //Name, Phone number, Message
  NoEmailAddress = '3',
  //Name, Email address
  NoPhoneAndMessage = '4',
  //Name, Phone number
  NoEmailAddressAndMessage = '5',
}

export const leadInformation = [
  {
    label: 'Name, Email, Phone, Message',
    value: EnumLeadsInformationToCapture.All,
  },
  {
    label: 'Name, Email, Message',
    value: EnumLeadsInformationToCapture.NoPhone,
  },
  {
    label: 'Name, Phone, Message',
    value: EnumLeadsInformationToCapture.NoEmailAddress,
  },
  {
    label: 'Name,Email',
    value: EnumLeadsInformationToCapture.NoPhoneAndMessage,
  },
  {
    label: 'Name, Phone',
    value: EnumLeadsInformationToCapture.NoEmailAddressAndMessage,
  },
];

export enum EnumLeadsType {
  Compulsory = '1',
  Optional = '2',
}

export const leadType = [
  {
    label: 'Optional',
    value: EnumLeadsType.Optional,
  },
  {
    label: 'Compulsory',
    value: EnumLeadsType.Compulsory,
  },
];

interface IProps {
  sidebar: ISidebar;
  tourId: string;
  tour: any;
  leadConfiguration: ILeadConfig;
  setLeadConfiguration: (state: ILeadConfig) => void;
  savedChanges: boolean;
  setSavedChanges: (state: boolean) => void;
  isValidForm: boolean;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

const LeadGeneration: React.FC<IProps> = ({
  sidebar,
  tourId,
  tour,
  leadConfiguration,
  setLeadConfiguration,
  savedChanges,
  setSavedChanges,
  isValidForm,
  toggleNotification,
}) => {
  const { handleLogoutState } = useAuth();
  const [showCustomDropDownFormConfiguration, setShowCustomDropDownFormConfiguration] = useState<boolean>(false);
  const [showCustomDropDownFormVisualisation, setShowCustomDropDownFormVisualisation] = useState<boolean>(false);
  const [proAccount, setProAccount] = useState<boolean>(false);
  const config = useConfig();
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));

  useEffect(() => {
    if (config) {
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (sidebar.leadGeneration) {
      setProAccount(sidebar.leadGeneration);
    }
  }, [sidebar]);

  const onChangeFormConfiguration = (option: string) => {
    let selectedOption = {
      value: '',
      label: '',
    };
    leadInformation.map(opt => {
      if (opt.label === option) {
        selectedOption.value = opt.value;
        selectedOption.label = opt.label;
      }
      return selectedOption;
    });
    // setFormConfigurationValue(selectedOption.label);
    setLeadConfiguration({ ...leadConfiguration, leadsConfig: Number(selectedOption?.value) });
    toggleCustomDropdownFormConfiguration(false);
  };

  const toggleCustomDropdownFormConfiguration = (option?: boolean) => {
    if (proAccount) {
      if (option) {
        setShowCustomDropDownFormConfiguration(option);
      } else {
        setShowCustomDropDownFormConfiguration(!showCustomDropDownFormConfiguration);
      }
    }
  };

  const toggleCustomDropdownFormVisualisation = (option?: boolean) => {
    if (proAccount) {
      if (option) {
        setShowCustomDropDownFormVisualisation(option);
      } else {
        setShowCustomDropDownFormVisualisation(!showCustomDropDownFormVisualisation);
      }
    }
  };

  const onChangeFormVisualisation = (option: string) => {
    let selectedOption = {
      value: '',
      label: '',
    };
    leadType.map(opt => {
      if (opt.label === option) {
        selectedOption.value = opt.value;
        selectedOption.label = opt.label;
      }
      return selectedOption;
    });
    // setFormVisualisationValue(selectedOption.label);
    setLeadConfiguration({ ...leadConfiguration, leadsType: Number(selectedOption?.value) });
    toggleCustomDropdownFormVisualisation(false);
  };

  const updateLeadsGenerationConfiguration = async () => {
    setSavedChanges(!savedChanges);
    ReportCustomEvent(EnumCategory.LeadGeneration, EnumEvent.EnableForm);
    try {
      await updateTourLeadsConfig(leadConfiguration, tourId);
      toggleNotification(EnumNotificationType.Success, 'Tour has been updated');
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error);
    }
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionCheckoutSession(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, String(<Trans id='There was an issue creating payment session' />));
    }
  };

  return (
    <div className='settings-modal-container-profile'>
      <div className='update-tour-container'>
        <div className='display-flex flex-space-around'>
          <div>
            <div className='settings-choose-logo-text'>
              <Trans id='Enable the lead generation form for ' />
              <b>{(tour && ' ' + tour.name) || ''}.</b>
            </div>
            <div className='logo-tripod-requirements'>
              <Trans id='The lead generation form would be displayed when a user will open the virtual tour URL' />
            </div>
          </div>
          {proAccount ? (
            <div className='field-input-container'>
              <label className='switch-settings'>
                <input
                  id='leadsGeneration'
                  type='checkbox'
                  checked={leadConfiguration?.enableLeadGenerationForm || false}
                  disabled={!proAccount}
                  onClick={() => {
                    if (leadConfiguration?.enableLeadGenerationForm) {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.DisableLeadGenerationForm);
                    } else {
                      //Report Custom Category and Event
                      ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.EnableLeadGenerationForm);
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setLeadConfiguration({
                      ...leadConfiguration,
                      enableLeadGenerationForm: e.target.checked === true ? true : false,
                    })
                  }
                />
                <span id='slider-leads' className='slider-settings round-settings'>
                  <span className={`slider-text-${leadConfiguration?.enableLeadGenerationForm ? 'on' : 'off'}`}>
                    {leadConfiguration?.enableLeadGenerationForm ? 'ON' : 'OFF'}
                  </span>
                </span>
              </label>
            </div>
          ) : (
            <div className='field-input-container'>
              <label className='switch-settings'>
                <input id='enableTripod' type='checkbox' disabled={!proAccount} />
                <span id='slider-auto-start-tour' className='slider-settings-disable round-settings'></span>
              </label>
            </div>
          )}
        </div>
        <div className=''>
          <div className='leads-config-block'>
            <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '' }}>
              <Trans
                id='Lead email address'
                render={({ translation }) => (
                  <div className={proAccount ? 'create-tour-input-labels' : 'field-text-disable'}>{translation}</div>
                )}
              />
              <div>
                <Trans
                  id={'Where should we send your lead to?'}
                  render={({ translation }) => (
                    <input
                      id='input-lead-email'
                      onClick={() => {
                        //Report Custom Category and Event
                        ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                      }}
                      placeholder={String(translation)}
                      disabled={!proAccount}
                      value={leadConfiguration.email}
                      style={{ maxWidth: 'none', width: '100%' }}
                      className={` ${
                        leadConfiguration.email !== ''
                          ? 'general-field-complete'
                          : proAccount
                          ? 'general-field-input-text'
                          : 'general-field-input-disable'
                      }`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setLeadConfiguration({ ...leadConfiguration, email: e.target.value });
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '' }}>
              <Trans
                id='Form configuration'
                render={({ translation }) => (
                  <div
                    className={
                      proAccount
                        ? `create-tour-input-labels ${
                            showCustomDropDownFormConfiguration ? 'create-tour-input-labels-focus' : 'create-tour-input-labels'
                          } `
                        : 'field-text-disable'
                    }
                  >
                    {translation}
                  </div>
                )}
              />
              <div id=''>
                <Trans
                  id={'Choose your preferred config'}
                  render={({ translation }) => (
                    <CustomSelect
                      value={linguiTranslateToString(
                        leadInformation.find(a => a.value === String(leadConfiguration.leadsConfig))?.label || '',
                      )}
                      options={[
                        defineMessage({ message: 'Name, Email, Phone, Message' }),
                        defineMessage({ message: 'Name, Email, Message' }),
                        defineMessage({ message: 'Name, Phone, Message' }),
                        defineMessage({ message: 'Name,Email' }),
                        defineMessage({ message: 'Name, Phone' }),
                      ]}
                      changeDropdownValue={onChangeFormConfiguration}
                      showCustomDropDown={showCustomDropDownFormConfiguration}
                      setShowCustomDropDown={toggleCustomDropdownFormConfiguration}
                      customZIndex={2}
                      customClass={
                        proAccount
                          ? `general-field-input custom-select-leads-generation-viewer-settings ${
                              leadInformation.find(a => a.value === String(leadConfiguration.leadsConfig))?.label
                                ? ''
                                : 'general-field-input-empty'
                            } ${showCustomDropDownFormConfiguration ? 'general-field-focus' : 'general-field-input'} `
                          : 'general-field-input-disable custom-select-leads-generation-viewer-settings'
                      }
                      customArrow={'custom-arrow-leads-generation-viewer-settings'}
                      customDropdown={'custom-dropdown-leads-generation-viewer-settings'}
                      placeholder={String(translation)}
                    />
                  )}
                />
              </div>
            </div>
            <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '' }}>
              <Trans
                id='Form visualisation'
                render={({ translation }) => (
                  <div
                    className={
                      proAccount
                        ? `create-tour-input-labels ${
                            showCustomDropDownFormVisualisation ? 'create-tour-input-labels-focus' : 'create-tour-input-labels'
                          }`
                        : 'field-text-disable'
                    }
                  >
                    {translation}
                  </div>
                )}
              />
              <div>
                <Trans
                  id={"How you'd like the form displayed"}
                  render={({ translation }) => (
                    <CustomSelect
                      value={linguiTranslateToString(leadType.find(a => a.value === String(leadConfiguration.leadsType))?.label || '')}
                      options={[defineMessage({ message: 'Optional' }), defineMessage({ message: 'Compulsory' })]}
                      changeDropdownValue={onChangeFormVisualisation}
                      showCustomDropDown={showCustomDropDownFormVisualisation}
                      setShowCustomDropDown={toggleCustomDropdownFormVisualisation}
                      customZIndex={2}
                      customClass={
                        proAccount
                          ? `general-field-input custom-select-leads-generation-viewer-settings ${
                              leadType.find(a => a.value === String(leadConfiguration.leadsType))?.label ? '' : 'general-field-input-empty'
                            } ${showCustomDropDownFormVisualisation ? 'general-field-focus' : 'general-field-input'}`
                          : 'general-field-input-disable custom-select-leads-generation-viewer-settings'
                      }
                      customArrow={'custom-arrow-leads-generation-viewer-settings'}
                      customDropdown={'custom-dropdown-leads-generation-viewer-settings'}
                      placeholder={String(translation)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='leads-config-block'>
            <div className='leads-generation-option' style={{ color: !proAccount ? '#818AA6' : '' }}>
              <Trans
                id='Form message (optional)'
                render={({ translation }) => <div className='create-tour-input-labels'>{translation}</div>}
              />
              <div>
                <Trans
                  id={
                    "What do you want to say to your potential leads? Example: use the form below to request more information about this property;Your information won't be shared with anyone else"
                  }
                  render={({ translation }) => (
                    <textarea
                      onClick={() => {
                        //Report Custom Category and Event
                        ReportCustomEvent(EnumCategory.VtConfiguration, EnumEvent.EnableDisableGoogleAnalyticsIntegration);
                      }}
                      placeholder={String(translation)}
                      disabled={!proAccount}
                      style={{ height: '102px', maxWidth: 'none', width: '100%' }}
                      className={proAccount ? 'leads-text-area general-field-input-text-area' : 'general-field-input-disable'}
                      onChange={(e: any) => setLeadConfiguration({ ...leadConfiguration, leadsMessage: e.target.value })}
                    ></textarea>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='btn-lead-container'>
          {proAccount ? (
            <>
              {!savedChanges ? (
                <div
                  className={`btn-lead-settings ${isValidForm ? 'btn-lead-active-settings button-hover' : ''}`}
                  onClick={updateLeadsGenerationConfiguration}
                >
                  <Trans id='Save changes' />
                </div>
              ) : (
                <div className='btn-confirm'>
                  <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <g filter='url(#filter0_d_9829_12446)'>
                      <path
                        d='M8.59467 14.6036L3.71966 9.51892C3.42678 9.21345 3.42678 8.71815 3.71966 8.41265L4.7803 7.30637C5.07318 7.00087 5.54808 7.00087 5.84096 7.30637L9.125 10.7316L16.159 3.39512C16.4519 3.08965 16.9268 3.08965 17.2197 3.39512L18.2803 4.5014C18.5732 4.80687 18.5732 5.30216 18.2803 5.60767L9.65533 14.6036C9.36242 14.9091 8.88755 14.9091 8.59467 14.6036Z'
                        fill='white'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_d_9829_12446'
                        x='0.5'
                        y='0.166016'
                        width='21'
                        height='17.666'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation='1.5' />
                        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' />
                        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9829_12446' />
                        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9829_12446' result='shape' />
                      </filter>
                    </defs>
                  </svg>
                  <Trans id='Saved' />
                </div>
              )}
            </>
          ) : (
            <div className='save-information-button-container'>
              <button
                id='save-btn-your-branding'
                className='save-information-button button-active'
                onClick={() => {
                  ReportCustomEvent(EnumCategory.MainMenu, EnumEvent.UpdateToProAccount);
                  upgradeToProPlan();
                }}
              >
                <Trans id='Upgrade account to PRO to unlock this feature' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadGeneration;
