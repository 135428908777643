import { Trans } from '@lingui/react';
import React, { useEffect, useState } from 'react';
// import { EnumCategory, EnumEvent } from '../../../google-analytics-track-list';
// import { ReportCustomEvent } from '../../../GoogleAnalyticsConfig';
import tick from '../../../images/general-icons/subscription-tick.svg';
import { subscriptionOptions } from './subscription-options';
import comingSoon from '../../../images/general-icons/coming-soon.svg';
import { useConfig } from '../../utils/user-config-context/user-config-context';
import { IModules } from '../dashboard/dashboard';
import { EnumProPlan, EnumProUserStatus } from '../../../api-helper/interface/interfaces';
import { useAuth } from '../../../auth-routes/auth-context';
import { handleError } from '../../../api-helper/api-error-handling';
import { EnumNotificationType } from '../../utils/notifications/notification';
import { createSubscriptionCheckoutSession, createSubscriptionPortal } from '../../../api-helper/api-payment';
import { IUserSubscription } from './settings';
import Moment from 'react-moment';
import { linguiTranslateToString } from '../../utils/lingui-utils/utils-lingui';

interface IProps {
  user?: IUserSubscription;
  loadedManageSubscription: boolean;
  toggleNotification: (type: EnumNotificationType, message?: string) => void;
}

export enum EnumCurrency {
  USD = 'usd',
  GBP = 'gbp',
  EUR = 'eur',
}

const ManageSubscription: React.FC<IProps> = ({ user, loadedManageSubscription, toggleNotification }) => {
  const config = useConfig();
  const [paymentType, setPaymentType] = useState<EnumProPlan>(EnumProPlan.ProPlanMonthly);
  const [showPayment, setShowPayment] = useState(false);
  const [currency, setCurrency] = useState(linguiTranslateToString('gbp'));
  const { handleLogoutState } = useAuth();
  const [expiredPro, setExpiredPro] = useState(true);

  useEffect(() => {
    if (user?.proPlan === EnumProPlan.ProPlanYearly) {
      setPaymentType(EnumProPlan.ProPlanYearly);
    } else {
      setPaymentType(EnumProPlan.ProPlanMonthly);
    }
  }, [user]);

  const getPrice = () => {
    let price;
    if (paymentType === EnumProPlan.ProPlanMonthly) {
      price = '29.99';
    } else {
      price = '200';
    }
    if (currency === EnumCurrency.EUR) {
      return price + '€';
    } else if (currency === EnumCurrency.GBP) {
      return '£' + price;
    } else {
      return '$' + price;
    }
  };

  const upgradeToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionCheckoutSession(currency);
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, String(<Trans id='There was an issue creating payment session' />));
    }
  };

  const manageToProPlan = async () => {
    try {
      const createPaymentSession = await createSubscriptionPortal();
      if (createPaymentSession?.redirectUrl) {
        // redirect to payment checkout
        window.location.href = createPaymentSession.redirectUrl;
      }
    } catch (error) {
      const err = error as Error;
      handleError(err, handleLogoutState);
      toggleNotification(EnumNotificationType.Error, String(<Trans id='There was an issue creating payment session' />));
    }
  };

  useEffect(() => {
    if (config) {
      const paymentConfig = config.modules.find((a: IModules) => Object.keys(a)[0] === 'payment')?.payment;
      if (paymentConfig) {
        setShowPayment(paymentConfig);
      }
      const currency = config.countryInfo;
      if (currency) {
        setCurrency(currency.currency.toLowerCase());
      }
    }
  }, [config]);

  useEffect(() => {
    if (user?.proExpiration) {
      checkExpiryDate();
    }
  }, [user]);

  const checkExpiryDate = () => {
    const today = new Date();
    if (user && user?.proExpiration < today) {
      setExpiredPro(true);
    } else {
      setExpiredPro(false);
    }
  };

  return (
    <div className='settings-information-container' style={{ height: '92%' }}>
      {loadedManageSubscription && (
        <>
          {showPayment ? (
            <div className='settings-information-layout'>
              <div className='panelhook-container' style={{ cursor: 'default' }}>
                <div>
                  <div className='subscription-list-table'>
                    <div className='subscription-list-row-header'>
                      <div className='subscription-column-one-no-column'></div>
                      <div className='subscription-cell-price'>
                        {user?.proUser === EnumProUserStatus.Free ? (
                          <div className='your-plan-popup'>Your Plan</div>
                        ) : (
                          <div className='your-plan-popup your-plan-popup-empty'></div>
                        )}
                        <div style={{ fontWeight: 600, fontSize: '18px' }}>
                          <Trans id='Free' />
                        </div>
                        <div style={{ fontWeight: 600, fontSize: '32px' }}>
                          {currency === EnumCurrency.EUR ? '0€' : currency === EnumCurrency.GBP ? '£0' : '$0'}
                        </div>
                        <div style={{ fontWeight: 400, fontSize: '12px' }}>
                          <Trans id='per' /> {paymentType === EnumProPlan.ProPlanMonthly ? <Trans id='month' /> : <Trans id='year' />}
                        </div>
                      </div>
                      <div className='subscription-cell-price'>
                        {user?.proUser && user.proUser >= EnumProUserStatus.Pro ? (
                          <div className='your-plan-popup'>Your Plan</div>
                        ) : (
                          <div className='your-plan-popup your-plan-popup-empty'></div>
                        )}
                        <div style={{ fontWeight: 600, fontSize: '18px' }}>
                          <Trans id='Pro' />
                        </div>
                        <div style={{ fontWeight: 600, fontSize: '32px' }}>{getPrice()}</div>
                        <div style={{ fontWeight: 400, fontSize: '12px' }}>
                          <Trans id=' per' /> {paymentType === EnumProPlan.ProPlanMonthly ? <Trans id='month' /> : <Trans id='year' />}
                        </div>
                      </div>
                    </div>
                    {subscriptionOptions.map((option, index) => (
                      <div key={index} className='subscription-list-row'>
                        <div className='subscription-column-one'>{linguiTranslateToString(option.option)}</div>
                        <div className='subscription-column-two'>{option.free && <img src={tick} alt='tick' />}</div>
                        <div className='subscription-column-three'>{option.pro && <img src={tick} alt='tick' />}</div>
                      </div>
                    ))}
                    {!expiredPro && user?.proUser !== undefined && user.proUser >= EnumProUserStatus.Pro ? (
                      <div className='subscription-list-row-header'>
                        <div className='subscription-column-one-no-column' style={{ flex: ' 3 1' }}></div>
                        <div className='subscription-cell-price' style={{ marginTop: '15px', justifyContent: 'flex-start' }}>
                          <button
                            id='save-btn-virtual-tour-configuration'
                            className='save-information-button button-active button-hover'
                            style={{ background: '#fff', color: '#0E0333' }}
                            onClick={() => manageToProPlan()}
                          >
                            <Trans id='Downgrade to Free' />
                          </button>
                        </div>
                        <div className='subscription-cell-price' style={{ marginTop: '15px' }}>
                          <button
                            id='save-btn-virtual-tour-configuration'
                            className='save-information-button button-active button-hover'
                            onClick={() => manageToProPlan()}
                          >
                            <Trans id='Manage' />
                          </button>
                          <div className='cancel-text'>
                            {user.proUser === EnumProUserStatus.ProFreeTrial ? (
                              <Trans id='Your free trial expires on ' />
                            ) : user.proUser === EnumProUserStatus.ProPendingExpiry ? (
                              <Trans id='Your Pro Plan expires on ' />
                            ) : (
                              <Trans id='Your next payment will be on ' />
                            )}
                            <br />
                            <Moment format='DD/MM/YY'>{user.proExpiration}</Moment>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='subscription-list-row-header' style={{ marginTop: '15px' }}>
                        <div className='subscription-column-one-no-column' style={{ flex: ' 4 1' }}></div>
                        <div className='subscription-cell-price'></div>
                        <div className='subscription-cell-price' style={{ marginTop: '15px' }}>
                          <button
                            id='save-btn-virtual-tour-configuration'
                            className='save-information-button button-active button-hover'
                            onClick={() => upgradeToProPlan()}
                          >
                            <Trans id='Upgrade to PRO' />
                          </button>
                          <div className='cancel-text'>
                            <Trans id='You can cancel anytime!' />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='container-text-soon'>
              <div className='title-soon'>
                <Trans id='COMING SOON!' />
              </div>
              <div className='sub-text-soon'>
                <Trans id='In the meantime, we are giving to all the PRO features for FREE' />
              </div>
              <img className='img-coming-soon' src={comingSoon} alt='coming soon illustration' />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ManageSubscription;
