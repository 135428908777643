import { ITokenResponse, ISendPasswordEmail } from './interface/interfaces';
import { ClientApi } from '@vva/front';

const clientApi = new ClientApi();

// conditional window ENV for testing with jest
clientApi.setHeaders({
	'X-Api-Key': (window.ENV && window.ENV.REACT_APP_API_KEY ? window.ENV.REACT_APP_API_KEY : null) || process.env.REACT_APP_API_KEY,
});

const login = async (email: string, password: string): Promise<ITokenResponse> => {
	const loginData = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/login`, {
		email: email,
		password: password,
	});
	return loginData.data;
};

const getAuth = async (): Promise<any> => {
	const token = localStorage.getItem('Bearer');
	const authData = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/token/validate`,
		{
			token,
		},
	);
	return authData.data;
};

const signup = async (email: string, fullName: string, password: string): Promise<ITokenResponse> => {
	const signUpData = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/register`, {
		email: email,
		fullName: fullName,
		password: password,
	});
	return signUpData.data;
};

const authenticate = (jwt: string) => {
	return new Promise((resolve, reject): void => {
		if (typeof window !== 'undefined') {
			clientApi.setJwtToken(jwt);
			resolve('');
		} else {
			reject(new Error('No window object'));
		}
	});
};

const sendResetPasswordEmail = async (email: string): Promise<ISendPasswordEmail> => {
	const response = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/forgotpwd`, {
		email,
	});
	return response.data;
};

const reSendVerificationEmail = async (email: string): Promise<ISendPasswordEmail> => {
	const response = await clientApi.postData(
		`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/activationEmail`,
		{
			email,
		},
	);
	return response.data;
};

const resetPassword = async (password: string, token: string): Promise<any> => {
	const response = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/changepwd`, {
		token,
		password,
	});
	return response.data;
};

const activateAccount = async (token: string): Promise<any> => {
	const response = await clientApi.postData(`${window.ENV.REACT_APP_API_URL || process.env.REACT_APP_API_URL}/confirm`, {
		token,
	});
	return response.data;
};

const logout = () => {
	if (typeof window !== 'undefined') {
		localStorage.removeItem('Bearer');
		localStorage.removeItem('Language');
		window.location.reload();
	}
};

export { sendResetPasswordEmail, login, signup, authenticate, reSendVerificationEmail, logout, getAuth, resetPassword, activateAccount };
